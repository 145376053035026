import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider as TP } from '@material-ui/core/styles';
import { ThemeProvider as TP1 } from 'styled-components';
import { UseWalletProvider } from 'use-wallet';
import usePromptNetwork from './hooks/useNetworkPrompt';
import BanksProvider from './contexts/Banks';
import EmpFinanceProvider from './contexts/BombFinanceProvider';
import ModalsProvider from './contexts/Modals';
import store from './state';
import theme from './theme';
import newTheme from './newTheme';
import config, { daasDefinitions } from './config';
import Updaters from './state/Updaters';
import Loader from './components/Loader';
import Popups from './components/Popups';
import { RefreshContextProvider } from './contexts/RefreshContext';
import { getProviderUrl } from './utils/provider';

const Home = lazy(() => import('./views/Home'));
const Farm = lazy(() => import('./views/Farm'));
const Nodes = lazy(() => import('./views/Nodes'));
const BoardroomV2 = lazy(() => import('./views/BoardroomV2'));
const Autocompunder = lazy(() => import('./views/Autocompunder'));
const Bond = lazy(() => import('./views/Bond'));
const Stake = lazy(() => import('./views/Stake'));
const Sentinel = lazy(() => import('./views/Sentinel'));
const Defender = lazy(() => import('./views/Defender'));
const Detonator = lazy(() => import('./views/Detonator'));
const Reactor = lazy(() => import('./views/Reactor'));
const TermsAndConditions = lazy(() => import('./views/TermsAndConditions'));
const Daas = lazy(() => import('./views/Daas'));
const DaasSelection = lazy(() => import('./views/DaasSelection'));

const NoMatch = () => (
  <h3 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
    URL Not Found. <a href="/">Go back home.</a>
  </h3>
);

const App: React.FC = () => {
  // Clear localStorage for mobile users
  if (typeof localStorage.version_app === 'undefined' || localStorage.version_app !== '2.0') {
    localStorage.clear();
    localStorage.setItem('version_app', '2.0');
  }
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };
  window.addEventListener('resize', appHeight);
  appHeight();

  usePromptNetwork();

  const daasRoutes = () => Object.entries(daasDefinitions).map(([id, config]) => config.active && (
    <Route path={`/daas/${id.toLowerCase()}`} element={<Daas />} key={id} />
  ));

  return (
    <Providers>
      <Router>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/farm" element={<Farm />} />
            {/*<Route path="/nodes" element={<Nodes />} />*/}
            <Route path="/boardroomV2" element={<BoardroomV2 />} />
            <Route path="/bond" element={<Bond />} />
            <Route path="/stake" element={<Stake />} />
            {/*<Route path="/sentinel" element={<Sentinel />} />*/}
            {/*<Route path="/defender" element={<Defender />} />*/}
            {/*<Route path="/detonator" element={<Detonator />} />*/}
            {/*<Route path="/reactor" element={<Reactor />} />*/}
            <Route path="/autocompounder" element={<Autocompunder />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/daas" element={<DaasSelection />} />
            {daasRoutes()}
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Suspense>
      </Router>
    </Providers>
  );
};

const Providers: React.FC<any> = ({ children }: any) => {
  const providerUrl = getProviderUrl();
  return (
    <TP1 theme={theme}>
      <TP theme={newTheme}>
        <UseWalletProvider
          autoConnect={true}
          connectors={{
            injected: {
              chainId: [Number(config.chainId)],
            },
            walletconnect: {
              rpc: { [Number(config.chainId)]: providerUrl },
              chainId: [Number(config.chainId)],
              rpcUrl: providerUrl,
            },
            walletlink: {
              chainId: [Number(config.chainId)],
              url: providerUrl,
              appName: 'emp.money',
              appLogoUrl: 'https://storageapi.fleek.co/665fd57f-7704-49db-8c5e-e030cd569820-bucket/emp-new.png',
            },
            frame: {
              chainId: [Number(config.chainId)],
            },
            authereum: {
              chainId: [Number(config.chainId)],
            }
          }}
        >
          <Provider store={store}>
            <Updaters />
            <RefreshContextProvider>
              <EmpFinanceProvider>
                <ModalsProvider>
                  <BanksProvider>
                    <>
                      <Popups />
                      {children}
                    </>
                  </BanksProvider>
                </ModalsProvider>
              </EmpFinanceProvider>
            </RefreshContextProvider>
          </Provider>
        </UseWalletProvider>
      </TP>
    </TP1>
  );
};

export default App;
